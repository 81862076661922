.project-img{
    /* width: 100%; */
    width: 600px;
    height: 400px;
    /* height: 0; */
    /* padding-bottom: 56.25%; 16:9 aspect ratio */
    object-fit: cover;
    /* border-radius: 10px; */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.title{
    font-family: Signika;
}

#card{
    background-color: #010323;
    border: #F5DEB3 solid 2px;
    min-height: 60vh;
    max-height: 65vh;
    width: 90vw;
}