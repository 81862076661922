/* AboutMe.css */
.about-me-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust the height as needed */
    position: relative;
}

.central-image {
    max-height: 85%; /* Adjust based on your preference */
    /* z-index: 1; Ensures the image is above the background but below the icons */
}

.icon-container {
    position: absolute;
    display: flex;
    justify-content: center;
    top: 10%; /* Adjust based on your image and preference */
    /* z-index: 2; Ensures the icons are above the image */
}

.icon {
    width: 50px; /* Adjust as needed */
    height: 50px; /* Adjust as needed */
    border-radius: 50%;
    background-color: #ccc; /* Placeholder style, replace as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5rem; /* Adjust spacing between icons */
    transform-origin: bottom center; /* Adjust for precise positioning */
}

/* Individual icon positioning */
.icon:nth-child(1) { transform: rotate(-60deg); }
.icon:nth-child(2) { transform: rotate(-30deg); }
.icon:nth-child(3) { transform: rotate(0deg); }
.icon:nth-child(4) { transform: rotate(30deg); }

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.icon {
    animation: fadeInUp 0.6s ease-out forwards;
    /* Keep the existing styles */
}

/* Timing the animation */
.icon:nth-child(1) { 
    animation-delay: 0.2s; 
    /* Keep the existing styles */
}
.icon:nth-child(2) { 
    animation-delay: 0.4s; 
    /* Keep the existing styles */
}
.icon:nth-child(3) { 
    animation-delay: 0.6s; 
    /* Keep the existing styles */
}
.icon:nth-child(4) { 
    animation-delay: 0.8s; 
    /* Keep the existing styles */
}

