#header {
  background-color: #010323;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #F5DEB3;
  margin-left: 15%;
  margin-right: 15%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.btn {
  display: inline-block; 
  width: 220px;
  padding: 10px 20px;
  font-size: 1rem;
  color:#F5DEB3; 
  background-color: #010323; 
  border: 2px solid #F5DEB3; 
  /* border-radius: 8px; */
  text-decoration: none; 
  transition: background-color 0.3s, transform 0.3s; 
  transform: skewX(-10deg);
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn:hover {
  background-color: lighten(#010323, 10%); 
  transform: skewX(0deg) scale(1.05);
  /* border-bottom-width: 0.25rem;
  border-top-width: 0.25rem;  */
  border-left-width: 0;
  border-right-width: 0;
}

#logo{
  width: 25px;
}
