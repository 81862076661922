@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Signika:wght@400;600;700&family=Cairo:wght@400;600;700&family=Hind+Madurai:wght@400;500;600&display=swap');


/* Reset margin for heading and body */
html, body {
  width: 100%;
  /* overflow-x: hidden; */
  margin-right: 0;
  padding-right: 0;
}
* {
  /* outline: 1px solid red;  */
}

body {
  font-family: 'Hind Madurai', sans-serif;
  font-size: 16px; 
  color: #F5DEB3; 
  background-color: #010323;
}

h1 {
  font-family: 'Signika', sans-serif;
  font-size: calc(16px * 1.953); /* 1.250 ^ 3 = 1.953 */
}

h2, h3, h4, h5, h6 {
  font-family: 'Cairo', sans-serif;
}

li {
  font-size: calc(16px * 1.125); 
}

h2 {
  font-size: calc(16px * 1.563); 
}

h3 {
  font-size: calc(16px * 1.250); 
}

h4 {
  font-size: 16px; 
}

h5 {
  font-size: calc(16px / 1.250); 
}

h6 {
  font-size: calc(16px / 1.563); 
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
  }
}

@media (max-width:375px) {
  #cont{
    margin-left: 2%;
  }
}
