/* ContactModal.css */
.modalBackdrop {
    /* background-color: black; */
    /* opacity: 0.5; */
    z-index: 5;
}

.modalContent {
    /* Styles for the modal's content */
    background-color: #F2F46B;
    color: #111377;
    padding: 20px;
    opacity: 1;
    /* width: 50%; */
    /* margin: 0 auto; */

}
